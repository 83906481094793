@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

body {
  margin: 0;
}

:root {

  --linear: linear-gradient(180deg, #FFFFFF 55.88%, #FEF9ED 100%);
  --linear2: linear-gradient(0deg, rgba(242, 195, 71, 0.1), rgba(242, 195, 71, 0.1)),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);

  --dark: #2E3147;
  --primary: #4784CA;
  --secondary: #00844F;

 


}


body {
  font-family: 'Plus Jakarta Sans', sans-serif;
}


.ant-radio-button-checked {
    background-color: var(--primary);
}

.ant-radio-button-checked:hover {
  color: var(--primary);
}


.otp-group {
  display: flex;
  width: 100%;
  max-width: 360px;
  column-gap: 10px;
}

.otp-input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}



.loader_container {
  position: fixed;
  z-index: 9999;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);

}

.ant-spin .ant-spin-dot-item {
  background-color: var(--primary);
}

.ant-table-cell {
  color: #2E3147;
  font-weight: 400;
}


.btn-pressed {
  background: #EEF0F4;
  border-radius: 15%;
  box-shadow: inset 9.91px 9.91px 20px #D9DADE, inset -9.91px -9.91px 20px #FFFFFF;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ant-select-selector{
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  border: 0px !important;
}


.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select{
  width: 160px !important;
  height: 160px !important;
}

.w-\[fit-content\] {
  width: fit-content !important ;
}

.ant-upload-list-item-container{
  width: 160px !important;
  height: 160px !important;
}

.ant-menu-light.ant-menu-root.ant-menu-inline{
  background-color: transparent !important;
  border-inline-end: 0px;
}

.ant-switch{
  background-color: #E5E5E5 !important;
}

.ant-switch-checked{
  background-color: #4784CA !important;
}
.ant-btn-primary{
  background-color: #4784CA !important;
}

.tax-table {
  @apply w-full;
  border: 1px solid #eeeeee;
}
.tax-table thead {
  background-color: #bbbbbb;
}
.tax-table tr {
  border-bottom: 1px solid #eeeeee;
}
.tax-table tr:hover {
  background-color: #eeeeee;
}
.tax-table th, .tax-table td {
  @apply text-left p-2;
}
@media (max-width: 767px) {
  .ant-menu-item {
    padding-left: 0 !important;
  }
  .ant-menu-submenu-title{
    padding-left: 0 !important;
  }
  .menubar{
    position: absolute;
    z-index: 200;
  }
}